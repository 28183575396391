.clock {
    position: relative;
    width: 100%;
    height: 100%;
    font-weight: 300;
    letter-spacing: 8px;
    color: var(--theme-color-1);
    font-family: 'Jura';
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.clock .column,
.clock .colon {
    display: inline-block;
    font-size: 36px;
    line-height: 32px;
    vertical-align: top;
}

.clock .column {
    transition: 0.3s;
}

.clock .colon {
    transform: translateY(calc(50% - 20px));
    transition: 0.3s;
}

.clock .colon::after {
    content: ":";
}

.clock .num {
    opacity: 0.25;
    transition: 0.5s;
}

.clock .num.visible {
    opacity: 1;
    text-shadow: 1px 1px 10px #fff;
}

.clock .num.near {
    opacity: 0.35;
}

.clock .num.far {
    opacity: 0.15;
}

.clock .num.distant {
    opacity: 0.1;
}