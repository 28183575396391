.main {
    position: relative;
    width: 100%;
    height: 100%;
    background: url('../../../public/images/background.webp') no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 16 / 9;
    gap: 20px;
    overflow: hidden;

    .profile {
        position: relative;
        width: 55%;
        height: 90%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        pointer-events: none;

        .about {
            padding: 5px;
            position: relative;
            width: 100%;
            height: 10%;
            pointer-events: all;

            &.go {
                opacity: .5;
                transform: translateY(50px);
                animation: origin .5s forwards ease-out;
            }

            h1 span {
                color: var(--theme-color-1);
            }

            >span {
                margin: 10px 0;
                text-align: center;
            }

            .weather {
                position: relative;
                height: 30%;
                width: 100%;
                list-style: none;
                display: flex;
                align-items: center;

                li {
                    position: relative;
                    padding: 2px 10px;
                    display: flex;
                    align-items: center;
                    background: var(--box-color);
                    border-radius: 10px;
                    font-size: 14px;

                    svg {
                        margin: 0 5px;
                        position: relative;
                    }
                }
            }
        }

        .me {
            padding: 5px;
            position: relative;
            width: 100%;
            height: 30%;
            border-radius: 5px;
            background: var(--box-color);
            backdrop-filter: blur(5px);
            box-shadow: 0px 0px 15px var(--box-shadow);
            overflow: hidden;
            pointer-events: all;

            &.go {
                opacity: .5;
                transform: translateY(50px);
                animation: origin .5s forwards ease-out;
            }

            h3 {
                margin: 20px;

                span {
                    padding: 5px 8px;
                    border-radius: 15px;
                    background: var(--box-color);
                    box-shadow: 0px 0px 15px var(--box-shadow);
                    font-size: 14px;
                    margin-left: 10px;
                    transition: .5s;

                    &:hover {
                        padding: 5px 11px;
                        background: var(--theme-color-2);
                    }
                }
            }

            h1 {
                margin: 10px 0 5px 20px;
            }

            span {
                margin: 0 20px;
            }

            .tech {
                position: absolute;
                top: 25%;
                right: -50px;
                transform: rotate(-25deg);
                width: 80%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                list-style: none;

                li {
                    position: relative;
                    width: 100%;
                    height: 50%;
                    white-space: nowrap;
                    display: flex;

                    &:nth-child(2) {
                        margin-left: 25%;
                    }

                    img {
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        left: 100%;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        -webkit-user-drag: none;
                        animation-name: scroll;
                        animation-timing-function: linear;
                        animation-iteration-count: infinite;
                        animation-duration: 40s;
                        transition: .5s;

                        &:hover {
                            transform: scale(1.1);
                        }

                        &.docker,
                        &.vscode {
                            height: 85%;
                            margin-left: 20px;
                        }

                        @for $i from 1 through 5 {
                            &:nth-child(#{$i}) {
                                animation-delay: calc(40s / 5 * (5 - $i) * -1);
                            }
                        }
                    }
                }
            }

            .id {
                position: absolute;
                margin: 30px 20px;
                bottom: 0;
                padding: 5px 20px;
                border-radius: 25px;
                background: var(--theme-color-2);
                font-size: 14px;
                text-align: center;
                transition: .5s;
                transform: translateY(0px);
            }

        }

        .post {
            padding: 5px;
            position: relative;
            width: 100%;
            height: 40%;
            border-radius: 5px;
            background: var(--box-color);
            backdrop-filter: blur(5px);
            box-shadow: 0px 0px 15px var(--box-shadow);
            pointer-events: all;
            display: flex;
            align-items: center;
            justify-content: center;

            &.go {
                opacity: .5;
                transform: translateY(50px);
                animation: origin .5s forwards ease-out;
            }

            .views {
                position: relative;
                width: 99%;
                height: 98%;
                background: var(--box-color);
                border-radius: 5px;
                display: flex;
                align-items: center;
                overflow: hidden;

                .slider {
                    position: relative;
                    width: 300%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    transition: .5s;

                    div {
                        position: relative;
                        height: 100%;
                        width: 100%;
                        flex: 0 0 100%;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        svg {
                            margin: 0 5px;
                        }

                        p {
                            margin: 5px 0;
                        }
                    }
                }

            }

            .switch {
                position: absolute;
                bottom: 5%;
                right: 2%;
                padding: 5px;

                label {
                    margin: 5px;

                    input {
                        display: none;

                        &:checked+span {
                            background-color: var(--theme-color-3);
                        }
                    }

                    span {
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        background: #fff;
                        border-radius: 50%;
                        position: relative;
                        cursor: pointer;
                        user-select: none;
                        transition: .5s;
                    }
                }

            }
        }

        .link {
            padding: 5px 0;
            position: relative;
            width: calc(100% + 10px);
            height: 5%;
            pointer-events: all;

            &.go {
                opacity: .5;
                transform: translateY(50px);
                animation: origin .5s forwards ease-out;
            }

            ul {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;

                a {
                    padding: 5px 0;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    text-decoration: none;
                    font-size: 18px;
                    font-weight: bold;
                    background: var(--box-color);
                    backdrop-filter: blur(5px);
                    box-shadow: 0px 0px 15px var(--box-shadow);
                    transition: .5s;

                    &:hover {
                        background: var(--box-color-hover);
                        transform: scale(1.01);
                    }

                    svg {
                        margin: 0 10px;
                    }

                    &.steam {
                        color: var(--link-steam);

                        &:hover {
                            color: var(--link-steam-hover);
                        }
                    }

                    &.github {
                        color: var(--link-github);

                        &:hover {
                            color: var(--link-github-hover);
                        }
                    }

                    &.cafe {
                        color: var(--link-cafe);

                        &:hover {
                            color: var(--link-cafe-hover);
                        }
                    }
                }
            }
        }

        >span {
            position: relative;
            text-decoration: none;
            font-weight: bold;
            margin: 0 5px;
            pointer-events: all;
            color: var(--text-white-opc5);

            &.go {
                opacity: .5;
                transform: translateY(50px);
                animation: origin .5s forwards ease-out;
            }
        }
    }

    .widget {
        position: relative;
        width: 15%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        pointer-events: none;

        .clock {
            padding: 5px;
            position: relative;
            width: 100%;
            height: 10%;
            border-radius: 5px;
            background: var(--box-color);
            backdrop-filter: blur(5px);
            box-shadow: 0px 0px 15px var(--box-shadow);
            pointer-events: all;
            display: flex;
            align-items: center;
            justify-content: center;

            &.go {
                opacity: .5;
                transform: translateY(50px);
                animation: origin .5s forwards ease-out;
            }
        }

        .sign {
            padding: 5px;
            position: relative;
            width: 100%;
            height: 20%;
            border-radius: 5px;
            background: var(--box-color);
            backdrop-filter: blur(5px);
            box-shadow: 0px 0px 15px var(--box-shadow);
            pointer-events: all;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                position: relative;
                height: 80%;
                max-width: 100%;
            }

            &.go {
                opacity: .5;
                transform: translateY(50px);
                animation: origin .5s forwards ease-out;
            }
        }

        .mini {
            padding: 5px;
            position: relative;
            width: 100%;
            height: 30%;
            background: var(--box-color);
            border-radius: 5px;
            background: var(--box-color);
            backdrop-filter: blur(5px);
            box-shadow: 0px 0px 15px var(--box-shadow);
            pointer-events: all;

            &.go {
                opacity: .5;
                transform: translateY(50px);
                animation: origin .5s forwards ease-out;
            }

            h3 {
                margin: 5px 10px;
                color: var(--theme-color-1);

                svg {
                    font-size: 14px;
                    margin-right: 5px;
                }
            }

            p {
                margin: 2px 10px;
                font-weight: bold;
                font-size: 18px;
                line-height: 28px;
                letter-spacing: 1px;

                &.country {
                    span {
                        color: var(--theme-color-2);
                    }
                }

                &.oc {
                    span {
                        color: var(--theme-color-1);

                        span {
                            color: var(--theme-color-3);
                        }
                    }
                }

                &.ip {
                    span {
                        color: var(--theme-color-2);
                        cursor: pointer;
                        user-select: none;
                    }
                }
            }
        }

        .goto {
            padding: 5px;
            position: relative;
            width: 100%;
            height: 25%;
            background: var(--box-color);
            border-radius: 5px;
            background: var(--box-color);
            backdrop-filter: blur(5px);
            box-shadow: 0px 0px 15px var(--box-shadow);
            pointer-events: all;

            &.go {
                opacity: .5;
                transform: translateY(50px);
                animation: origin .5s forwards ease-out;
            }

            h3 {
                margin: 5px 10px;
                color: var(--text-color);

                svg {
                    font-size: 14px;
                    margin-right: 5px;
                }
            }

            ul {
                margin: 5px 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                a {
                    margin: 5px 0;
                    padding: 10px 5px;
                    position: relative;
                    text-decoration: none;
                    background: var(--box-color);
                    border-radius: 5px;
                    color: var(--text-color);
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 10px;
                    letter-spacing: 1px;
                    transition: .5s;

                    &:hover {
                        background: var(--box-color-hover);
                        transform: scale(1.01);
                    }

                    svg {
                        margin: 0 5px;
                    }

                    &.post {
                        >svg {
                            color: var(--theme-color-3);
                        }
                    }

                    &.rss {
                        >svg {
                            color: var(--rss);
                        }
                    }

                    span {
                        position: absolute;
                        right: 20px;
                        opacity: 0;
                        transition: .5s;
                    }

                    &:hover {
                        span {
                            opacity: 1;
                            right: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:768px) {
    .main {
        background-size: auto 100%;
        flex-direction: column;
    }

    .main .profile {
        width: 80%;
        height: 40%;
    }

    .main .widget {
        width: 80%;
        height: 40%;
    }
}

@keyframes scroll {
    to {
        left: -200px;
    }
}

@keyframes run {
    to {
        top: 0px;
    }
}

@keyframes push {
    to {
        width: 100%;
    }
}

@keyframes origin {
    to {
        transform: translateY(0px);
        opacity: 1;
    }
}