* {
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

:root {
  --background-color: #e0e0e0;
  --box-color: #ededed49;
  --box-color-hover: #ededed86;
  --box-shadow: #1d1e2249;
  --text-color: #1d1e22;
  --text-shadow: #1d1e22;
  --text-white: #ffffff;
  --text-white-opc5: #ffffff50;
  --theme-color-1: #e04214;
  --theme-color-2: #f2ba4b;
  --theme-color-3: #34c5de;
  --theme-color-4: #0a9f79;
  --link-steam: #000000;
  --link-steam-hover: #156e94;
  --link-github: #171515;
  --link-github-hover: #022964;
  --link-cafe: #0D0C22;
  --link-cafe-hover: #a37043;
  --rss: #ee802f;
}

html {
  scroll-behavior: smooth;
}

html,
body,
#root {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: var(--background-color);
  overflow: hidden;
}