.main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: var(--background-color);
    z-index: 999;
    transition: 1s;
}

.main.hide {
    opacity: 0;
}